import './src/styles/globals.css';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
            <ToastContainer />
            {element}
        </GoogleReCaptchaProvider>
    );
};

let nextRoute = ``;
export const onPreRouteUpdate = ({ location }) => {
    nextRoute = location.pathname
};

window.addEventListener("error", event => {
    if (/loading chunk \d* failed./i.test(event.reason)) {
        if (nextRoute) {
            window.location.pathname = nextRoute
        }
    }
});