import React from "react";
import { Link } from "gatsby";

const footerMenu = {
    menuItems: [
        {
            title: "TRADEUPBC",
            hover: "hey",
            url: "https://www.tradeupbc.ca",
        },
        {
            title: "International",
            url: "/international",
        },
        {
            title: "Privacy Policy",
            url: "/privacypolicy",
        },
    ],
};

const Footer = () => {
    return (
        <div className="z-1 relative mt-auto w-full bg-darkBlue pb-[30px] sm:pb-[0px]">
            <div className="flex flex-wrap justify-between pr-[25px] pl-[32px] pb-[15px] md:pb-[0px]">
                <div className="flex flex-col md:order-1">
                    <div className="mt-[30px] flex w-full flex-col flex-wrap sm:flex-row">
                        {footerMenu?.menuItems.map((menu, index) => {

                            if (menu.url.startsWith("http")) {
                                return (
                                    <div className="group relative">
                                        <a
                                            target={"_blank"}
                                            href={`${menu.url}`}
                                            key={index}
                                            className="font-navSecondary mt-120px w-fit whitespace-nowrap px-[15px] py-[5px] font-robotoCondensed uppercase text-white md:py-0 hover:text-gray-300"
                                        >
                                            {menu.title}
                                            <span
                                                className="absolute left-0 right-0 bottom-full mb-2 hidden w-max max-w-xs px-2 py-1 text-xs text-black bg-white border border-gray-300 rounded shadow-lg whitespace-normal group-hover:block normal-case">
                                                TradeUp courses are open to experienced tradespeople who want to update their skills, advance their careers, or strengthen their businesses.
                                            </span>
                                        </a>
                                    </div>
                                );
                            }

                            return (
                                <Link to={`${menu.url}`} key={index}
                                      className="font-navSecondary mt-120px w-fit whitespace-nowrap px-[15px] py-[5px] font-robotoCondensed uppercase text-white md:py-0">
                                    {menu.title}
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <p className="font-tinyText my-[20px] font-robotoCondensed text-white md:mt-[90px] md:mb-[30px]">
                    © Copyright {new Date().getFullYear()} Trades Training Consortium of British Columbia. All rights
                    reserved.
                </p>
            </div>
        </div>
    );
};

export default Footer;
