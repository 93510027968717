import React, { useState } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import SearchIcon from '../images/icons/SearchIcon.svg';

const Search = (props) => {
    const { closeMenu } = props;
    const { allPublicProgram } = useStaticQuery(graphql`
        {
            allPublicProgram {
                nodes {
                    uri
                    name
                }
            }
        }
    `);

    const [query, setQuery] = useState(null);
    
    const logSearchEvent = (programName) => {
        typeof window !== 'undefined' &&
            window.gtag("event", "search", {
                "search_term": programName
            });
    }

    const setPage = (e, program) => {
        //custom gtag event only works on production and errors in develop, so bypass develop errors with try/catch
        try {
            logSearchEvent(program.name);
        } catch (e) {}
        navigate(program.uri);
        setQuery('');
        closeMenu();
    };

    let results = [];

    if (query) {
        results = allPublicProgram.nodes.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()));
    }

    return (
        <div>
            <div className="relative">
                <input
                    name="query"
                    autoComplete="off"
                    value={query || ''}
                    onChange={(event) => setQuery(event.target.value)}
                    type="search"
                    className="border-gray-300 h-8 w-full rounded border pr-2 pl-8 shadow-sm lg:w-48 font-robotoCondensed outline-offset-0"
                    placeholder="Find a Program"
                />

                <div onClick={(e) => setPage(e, results)} className="absolute top-2 left-3">
                    <SearchIcon className="text-gray-400 hover:text-gray-500 z-20" />
                </div>
                <div className="absolute z-50 flex w-auto min-w-full flex-col overflow-hidden rounded shadow-lg">
                    {results.map((programs, index) => {
                        return (
                            <div onClick={(e) => setPage(e, programs)} className="border-gray-800 cursor-pointer whitespace-nowrap border-b bg-white p-4 hover:bg-blue hover:text-white">
                                {programs.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Search;
