import React, { useState } from "react";
import { Link } from "gatsby";
import { Disclosure, Menu } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLockBodyScroll } from "../../hooks/useLookBodyScroll";

import Logo from "../images/icons/logo.svg";
import Search from "../components/Search";
import Edges from "../components/Edges";

const headerMenu = {
    tagLine: "The hub for BC's public trades training programs & services.",
    menuOne: [
        {
            title: "About",
            url: "/about",
        },
        {
            title: "Contact",
            url: "/contact",
        },
    ],
    menuTwo: [
        {
            title: "Trade Programs",
            url: "/tradeprograms",
            isExternal: false,
        },
        {
            title: "How Trades Training Works",
            url: "/howtradetrainingworks",
            isExternal: false,
        },
        {
            title: "Jobs Board",
            url: "https://jobs.tradestrainingbc.ca",
            isExternal: true,
        },
        {
            title: "Financial Assistance",
            url: "/financialassistance",
            isExternal: false,
        },
    ],
};

const Header = (props) => {
    return (
        <Edges size="xl" className="z-20">
            <Disclosure as="nav" className="relative w-full bg-white text-black">
                {({ open, close }) => (
                    <>
                        <div className="flex flex-col items-start justify-between py-[20px] px-[30px] sm:flex-row">
                            <div className="z-40 flex">
                                <div className="flex flex-col items-start justify-between ">
                                    <Link to="/" className="w-[130px]">
                                        <Logo className="mb-[20px]" />
                                    </Link>
                                    <p>{headerMenu.tagLine}</p>
                                </div>
                            </div>

                            {open && (
                                <Disclosure.Panel className="block lg:hidden ">
                                    {({ open, close }) => (
                                        <>
                                            {open && useLockBodyScroll()}
                                            <div
                                                className="absolute left-0 right-0 top-0 z-30 h-[200px] w-full bg-white" />
                                            <div
                                                className="fixed left-0 right-0 bottom-0 top-0 z-20 h-full w-full bg-darkGray bg-opacity-75"
                                                onClick={() => close()} />
                                        </>
                                    )}
                                </Disclosure.Panel>
                            )}
                            <div className="mr-[30px] mt-[6px] flex w-full items-end sm:w-fit lg:mr-[0px] lg:mt-[0px] ">
                                <div className="flex flex-col items-end pt-[1px] ">
                                    <div className="flex items-center ">
                                        <div className="hidden pr-[15px] lg:block">
                                            <Search closeMenu={close} />
                                        </div>
                                        <div className="hidden lg:flex">
                                            {headerMenu.menuOne.map((item, i) => {
                                                return (
                                                    <Link key={i} to={item.url}
                                                          className="mx-[15px] uppercase no-underline hover:text-blue">
                                                        {item.title}
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className="hidden lg:flex">
                                        {headerMenu.menuTwo.map((item, i) => {
                                            if (item.isExternal) {
                                                return (
                                                    <a key={i} href={item.url}
                                                       className="mx-[10px] mt-[45px] uppercase no-underline hover:text-blue">
                                                        {item.title}
                                                    </a>
                                                );
                                            }

                                            return (
                                                <Link key={i} to={item.url}
                                                      className="mx-[10px] mt-[45px] uppercase no-underline hover:text-blue">
                                                    {item.title}
                                                </Link>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="flex items-center lg:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button
                                        className="text-gray-400 hover:text-gray-500 hover:bg-gray-100 absolute top-[15px] right-[15px] z-40 inline-flex items-center justify-center rounded-md p-2 focus:outline-none">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? <XMarkIcon className="block h-10 w-10 text-[#414141] "
                                                           aria-hidden="true" /> :
                                            <Bars3Icon className="block h-10 w-10 text-[#414141]" aria-hidden="true" />}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="absolute z-40 w-full bg-white lg:hidden">
                            <div className="space-y-1 pt-2 pb-3">
                                <div className="relative ml-auto mr-[15px] block w-full px-[30px] lg:hidden">
                                    <Search closeMenu={close} />
                                </div>
                                {headerMenu.menuOne.map((item, i) => (
                                    <Link
                                        to={item.url}
                                        key={i}
                                        onClick={() => close()}
                                        className="text-base text-gray-500 hover:text-gray-800 hover:bg-gray-100 block px-4 py-2 font-medium uppercase no-underline hover:text-blue"
                                    >
                                        {item.title}
                                    </Link>
                                ))}
                            </div>
                            <div className="border-gray-200 border-t pt-4 pb-3">
                                <div className="flex items-center">
                                    <div className="mt-3 space-y-1">
                                        {headerMenu.menuTwo.map((item, i) => {
                                            if (item.isExternal) {
                                                return (
                                                    <a
                                                        href={item.url}
                                                        className="text-base text-gray-500 hover:text-gray-800 hover:bg-gray-100 block px-4 py-2 font-medium uppercase no-underline hover:text-blue"
                                                    >
                                                        {item.title}
                                                    </a>
                                                );
                                            }

                                            return (
                                                <Link
                                                    to={item.url}
                                                    key={i}
                                                    onClick={() => close()}
                                                    className="text-base text-gray-500 hover:text-gray-800 hover:bg-gray-100 block px-4 py-2 font-medium uppercase no-underline hover:text-blue"
                                                >
                                                    {item.title}
                                                </Link>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </Edges>
    );
};

export default Header;
