exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-course-refresher-tsx": () => import("./../../../src/pages/CourseRefresher.tsx" /* webpackChunkName: "component---src-pages-course-refresher-tsx" */),
  "component---src-pages-financialassistance-tsx": () => import("./../../../src/pages/financialassistance.tsx" /* webpackChunkName: "component---src-pages-financialassistance-tsx" */),
  "component---src-pages-howtradetrainingworks-tsx": () => import("./../../../src/pages/howtradetrainingworks.tsx" /* webpackChunkName: "component---src-pages-howtradetrainingworks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-international-tsx": () => import("./../../../src/pages/international.tsx" /* webpackChunkName: "component---src-pages-international-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-tradeprograms-tsx": () => import("./../../../src/pages/tradeprograms.tsx" /* webpackChunkName: "component---src-pages-tradeprograms-tsx" */),
  "component---src-templates-program-tsx": () => import("./../../../src/templates/program.tsx" /* webpackChunkName: "component---src-templates-program-tsx" */),
  "component---src-templates-program-type-tsx": () => import("./../../../src/templates/programType.tsx" /* webpackChunkName: "component---src-templates-program-type-tsx" */)
}

